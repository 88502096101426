/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { LinksFunction, LoaderFunctionArgs, json } from '@remix-run/node';
import {
Links,
LiveReload,
Meta,
Outlet,
Scripts,
ScrollRestoration,
useLoaderData,
useLocation,
useMatches,
useRouteError } from
'@remix-run/react';
import classNames from 'classnames';
import { wrap } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import bigCalendarStylesheetUrl from 'react-big-calendar/lib/css/react-big-calendar.css';
// import reactCalendarStylesheet from 'react-calendar/dist/Calendar.css'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastContainer, toast } from 'react-toastify';
import tostifyStylesheetUrl from 'react-toastify/dist/ReactToastify.css';
import io from 'socket.io-client';
import CircularProgressBarStyleUrl from '../styles/CircularProgressBar.css';
import { ConfirmationServiceProvider } from './components/ConfirmationContext';
import ImageCropProvider from './components/shared/image-crop/ImageCropProvider';
import tailwindStylesheetUrl from './styles/tailwind.css';
import { Modal, ModalProps } from 'app/components/Modal';
import Spinner from 'app/components/Spinner';
import { darkTheme } from 'app/darkTheme';
import { fullstory } from 'app/fullstory';
import { useRequest } from 'app/hooks/useRequest';
import { intercom } from 'app/intercom';
import { lightTheme } from 'app/lightTheme';
import { bg } from 'lib/background';
import { getCookie } from 'lib/getCookie';
import { debug, log, logWarn } from 'lib/logging';
import { setCookie } from 'lib/setCookie.client';
import { sleep } from 'lib/sleep';

export const links: LinksFunction = () => {
  return [
  { rel: `stylesheet`, href: bigCalendarStylesheetUrl },
  // { rel: `stylesheet`, href: reactCalendarStylesheet },
  { rel: `stylesheet`, prefetch: `render`, href: tailwindStylesheetUrl },
  { rel: `stylesheet`, href: CircularProgressBarStyleUrl },
  { rel: `stylesheet`, href: tostifyStylesheetUrl },
  { rel: `stylesheet`, href: `https://rsms.me/inter/inter.css` }
  // { rel: `icon`, href: `/public/favicon.ico` },
  ];
};

export const meta = () => {
  return [];
};

export async function loader({ request }: LoaderFunctionArgs) {
  return json({ theme: getCookie(`theme`, request), disableReload: !!process.env.DISABLE_RELOAD });
}

const socket = io({
  transports: [`websocket`]
});

export function ErrorBoundary() {
  const error: any = useRouteError();
  const request = useRequest();

  const [tries, setTries] = useState(0);

  // eslint-disable-next-line no-console
  console.log(`generic error:`, error, error?.stack);
  // useEffect(() => {
  //   try {
  //     intercom()
  //   } catch (err) {
  //     logWarn(`intercom`, `failed to load`, err)
  //   }
  // }, [])

  useEffect(() => {
    if (location.hostname.match(/(localhost)/)) return;
    bg(async () => {
      if (error?.toString().match(/(not found|404)/)) {
        await sleep(3000);
        window.location.pathname = `/`;
      } else {
        let url = new URL(window.location.href);
        let t = Number(url.searchParams.get(`t`) || 0);
        setTries(t);
        if (t < 3) {
          url.searchParams.set(`t`, String(t + 1));
          window.location.href = url.toString();
        } else {
          bg(async () => {
            if (location.hostname.match(/(localhost)/)) return;
            await Promise.all([
            async () => {
              try {
                fullstory();
                if (typeof window?.FS === `function`) {
                  window.FS(`trackEvent`, {
                    name: `error-page`,
                    properties: { error: String(error), stack: error?.stack }
                  });
                  log(`event logged`);
                } else {
                  logWarn(`fullstory not loaded`);
                }
              } catch (err) {
                logWarn(`fullstory`, `failed to load`, err);
              }
            },
            async () => {
              await request(`/api/error-report`, {
                method: `POST`,
                body: { message: String(error) + `\n` + error?.stack }
              });
            },
            async () => {
              await sleep(3000);
              window.location.pathname = `/`;
            }]
            );
          });
        }
      }
    });
  }, []);

  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <title>Trackable error</title>
        <Links />
        <link rel="icon" href={`/public/favicon/light.svg`} />
        <Scripts />
        <style>
          {`:root {\n` +
          Object.entries(lightTheme)?.
          map(([key, value]) => `\t--${key}: ${value};`).
          join(`\n`) +
          `\n}`}
        </style>
      </head>
      <body>
        {error?.toString().match(/(not found|404)/) ?
        <div className="flex h-screen flex-col items-center justify-center">This page could not be found</div> :

        <div className="flex h-screen flex-col items-center justify-center">
            {tries < 3 && typeof location !== `undefined` && !location.hostname.match(/(localhost)/) ?
          <Spinner size={10} /> :

          <>
                <p className="text-heading-xl text-trn-900">Something went wrong</p>
                <p className="mt-4 text-body-md text-trn-700">
                  We're having some unexpected problems. The team has been notified.
                </p>
                <p className="pt-4">
                  {/* <a className="btn-link" href="javascript:location.reload()"> */}
                  Hold on while we take you home...
                  {/* </a> */}
                </p>
              </>}

          </div>}

      </body>
    </html>);

}

let setContent: (elem: React.ReactElement | undefined | null) => void;
let setOptions: (options: OptionsType | undefined) => void;
let setNestedContent: (elem: React.ReactElement | undefined | null) => void;
let setNestedOptions: (options: OptionsType | undefined) => void;
let setModalOpen: (open: boolean) => void;
let setNestedModalOpen: (open: boolean) => void;
let isModalOpen: boolean;
let isNestedModalOpen: boolean;

type OptionsType = Omit<ModalProps, `open` | `children`>;
/**
 * opens a modal at body level
 * @Param elem. The element to render in the modal
 * @Param options. The options to pass to the modal
 * @Param allowNesting. Default value is true. There are cases where a modal opens instead of another eg. Integration modal. In such cases, allowNesting should be false
 */
export function showModal(
elem: React.ReactElement,
options: Partial<OptionsType> = {},
allowNesting: boolean | undefined = true)
{
  debug(`modal`, `showModal ready?`, setContent != null);
  if (isModalOpen && allowNesting) {
    setNestedContent?.(elem);
    options.onClose = wrap(options.onClose, (func) => {
      setNestedModalOpen?.(false);
      func?.();
    });
    setNestedOptions?.((options as any));
  } else {
    setContent?.(elem);
    options.onClose = wrap(options.onClose, (func) => {
      setModalOpen?.(false);
      func?.();
    });
    setOptions?.((options as any));
  }
}

export function closeModal() {
  if (isNestedModalOpen) {
    setNestedModalOpen?.(false);
  } else {
    setModalOpen?.(false);
  }
}

export default function Root(): React.ReactElement {
  const matches = useMatches();
  // const user = useOptionalUser()
  const data = useLoaderData<typeof loader>();
  const location = useLocation();
  const [theme, setTheme] = useState(data.theme || `light`);
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>();
  const [modalOpen, _setModalOpen] = useState(false);
  setModalOpen = _setModalOpen;
  const [nestedModalOpen, _setNestedModalOpen] = useState(false);
  setNestedModalOpen = _setNestedModalOpen;
  const [modalContent, _setModalContent] = useState<React.ReactElement | undefined | null>();
  setContent = _setModalContent;
  const [options, _setOptions] = useState<OptionsType>();
  setOptions = _setOptions;
  const [nestedModalContent, _setNestedModalContent] = useState<React.ReactElement | undefined | null>();
  setNestedContent = _setNestedModalContent;
  const [nestedOptions, _setNestedOptions] = useState<OptionsType>();
  const [hidePhi, setHidePhi] = useState(false);

  debug(`cookie`, `phi?`, hidePhi);

  setNestedOptions = _setNestedOptions;
  let current = (matches[matches.length - 1] as any);
  let title = `Trackable`;
  if (current?.handle?.title) {
    title += ` - ${current?.handle.title}`;
  }
  isModalOpen = modalOpen;
  isNestedModalOpen = nestedModalOpen;

  // useEffect(() => {
  //   if (!nestedModalOpen) {
  //     setTimeout(() => {
  //       _setNestedModalContent(null)
  //       _setNestedOptions(undefined)
  //     }, 300)
  //   }
  // }, [nestedModalOpen])

  // useEffect(() => {
  //   if (!modalOpen) {
  //     setTimeout(() => {
  //       _setModalContent(null)
  //       _setOptions(undefined)
  //     }, 300)
  //   }
  // }, [modalOpen])

  useEffect(() => {
    setHidePhi(getCookie<boolean>(`hidePhi`) === true);
  });

  useEffect(() => {
    debug(`modal`, `modalContent`, modalContent);
    if (modalContent != null) {
      _setModalOpen(true);
    } else _setModalOpen(false);
  }, [modalContent]);

  useEffect(() => {
    debug(`modal`, `nested modalContent`, nestedModalContent);
    if (nestedModalContent != null) _setNestedModalOpen(true);else
    _setNestedModalOpen(false);
  }, [nestedModalContent]);

  debug(`breadcrumbs`, `title`, current);

  useEffect(() => {
    try {
      fullstory();
    } catch (err) {
      logWarn(`fullstory`, `failed to load`, err);
    }
    try {
      intercom();
    } catch (err) {
      logWarn(`intercom`, `failed to load`, err);
    }
  }, []);

  // set timezone cookie
  useEffect(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setCookie(`timezone`, tz);
  });

  useEffect(() => {
    socket.on(`message`, (message: string) => {
      debug(`socket`, `client message`, message);
      toast(message);
    });

    return () => {
      socket.off(`message`);
    };
  }, []);

  useEffect(() => {
    function handleFirstTab(e) {
      if (e.keyCode === 9) {
        // the "I am a keyboard user" key
        document.body.classList.add(`user-is-tabbing`);
        window.removeEventListener(`keydown`, handleFirstTab);
      }
    }

    window.addEventListener(`keydown`, handleFirstTab);
    return () => window.removeEventListener(`keydown`, handleFirstTab);
  }, []);

  useEffect(() => {
    let val = getCookie<string>(`theme`);
    if (val) {
      setTheme(val);
      return;
    }
    // if (user?.type !== `super` || !window.matchMedia) return
    // const mediaQuery = `(prefers-color-scheme: dark)`
    // let handler = (event) => setTheme(event.matches ? `dark` : `light`)
    // window.matchMedia(mediaQuery).addEventListener(`change`, handler)
    // handler(window.matchMedia(mediaQuery))
    // return () => window.matchMedia(mediaQuery).removeEventListener(`change`, handler)
  }, []);

  useEffect(() => {
    if (!window.matchMedia) return;
    let handler = (event) => setIsDarkTheme(event.matches);
    let darkTheme = window.matchMedia(`(prefers-color-scheme: dark)`);
    handler(darkTheme);

    darkTheme.addEventListener(`change`, handler);
    return () => darkTheme.removeEventListener(`change`, handler);
  }, []);

  useEffect(() => {
    debug(`theme`, `is`, theme);
  }, [theme]);

  const faviconPath = useMemo(() => {
    if (location.pathname.match(/^\/scheduling/)) {
      return `/public/favicon/${isDarkTheme ? `scheduling-dark.svg` : `scheduling.svg`}`;
    }
    return `/public/favicon/${isDarkTheme ? `trackable-dark.svg` : `trackable.svg`}`;
  }, [isDarkTheme]);

  return (
    <html lang="en" className="bg-white">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <title>{title}</title>
        <Links />
        <link rel="icon" href={faviconPath} />
        <Scripts />
        <style>
          {`:root {\n` +
          Object.entries(theme === `dark` ? darkTheme : lightTheme)?.
          map(([key, value]) => `\t--${key}: ${value};`).
          join(`\n`) +
          `\n}`}
        </style>
      </head>
      {/*
        * TRK-2973: h-screen does not work well with Safari on iOS. height: 100vh considers the address bar height as
        * part of the height as well. A better solution is to use height: 100dvh. This will make the height of the
        * element equal to the height of the viewport, minus the height of the address bar. This will work on all
        * browsers and devices. A word of caution, h-dvh does not work on older browsers.
        */}
      <body className={classNames(`h-dvh w-screen overflow-hidden md:h-screen`, hidePhi && `hide-phi`)}>
        <DndProvider backend={HTML5Backend}>
          <ConfirmationServiceProvider>
            <ImageCropProvider>
              <Outlet />
              <ScrollRestoration />
              {!data.disableReload && <LiveReload />}
              <ToastContainer />
              <span id="tooltip-container" className="tooltip-wrapper absolute z-50 hidden whitespace-nowrap"></span>
              {/* eslint-disable-next-line deprecation/deprecation */}
              <Modal
              {...options!}
              children={modalContent}
              open={modalOpen}
              onClose={() => {
                // _setModalContent(undefined)
                _setModalOpen(false);
                options?.onClose?.();
              }}
              nestedModal={
              <Modal
              {...nestedOptions!}
              open={nestedModalOpen}
              children={nestedModalContent}
              onClose={() => {
                // _setNestedModalContent(undefined)
                _setNestedModalOpen(false);
                nestedOptions?.onClose?.();
              }} />} />



            </ImageCropProvider>
          </ConfirmationServiceProvider>
        </DndProvider>
      </body>
    </html>);

}